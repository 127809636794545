import React, { Component } from 'react';
import LoginLayout from '../../Components/LoginLayout/LoginLayout';

class LoginDisplay extends Component {
    state = {}
    render() {
        return (
            <div>
                <LoginLayout />
            </div>
        );
    }
}

export default LoginDisplay;