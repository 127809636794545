export const ActionType = {
    GET_HACKATONS: "GET_HACKATONS",
    POST_HACKATON: "POST_HACKATON",
    PUT_HACKATON: "PUT_HACKATON",
    DELETE_HACKATON: "DELETE_HACKATON",

    GET_USERS: "GET_USERS",
    POST_USER: "POST_USER",
    PUT_USER: "PUT_USER",
    DELETE_USER: "DELETE_USER",

    GET_TEAMS: "GET_TEAMS",
    POST_TEAM: "POST_TEAM",
    PUT_TEAM: "PUT_TEAM",
    DELETE_TEAM: "DELETE_TEAM"
}